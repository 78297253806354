<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Belanja Non Teknis, Rekomendasi Kebijakan, Dokumen Teknis
        </h5>
      </b-col>
      <alert-module-detail :doc-data="nonTeknis" />
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Nama Item Pengadaan Non Teknis yang akan dilaksanakan.'"
          label="Nama Item Pengadaan Non Teknis"
          label-for="vi-nama_item"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Item Pengadaan Non Teknis"
            rules="required"
          >
            <b-form-input
              id="vi-nama_item"
              v-model="nonTeknis.nama"
              required
              name="nama_item"
              placeholder="Nama Item Pengadaan Non Teknis"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
          :label="`Total Anggaran ${(nonTeknis.total_anggaran) ? ': Rp. ' + Number(nonTeknis.total_anggaran).toLocaleString() : ''}`"
          label-for="vi-total_anggaran_non"
        >
          <validation-provider
            #default="{ errors }"
            name="Total Anggaran"
            rules="required"
          >
            <b-form-input
              id="vi-total_anggaran_non"
              v-model="nonTeknis.total_anggaran"
              type="number"
              required
              name="total_anggaran_non"
              placeholder="Total Anggaran"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Apakah Item Belanja Memiliki Belanja Item Pendukung'"
          label="Memiliki Belanja Item Pendukung?"
          label-for="vi-is_mou"
        >
          <validation-provider
            #default="{ errors }"
            name="Memiliki Belanja Item Pendukung?"
            rules="required"
          >
            <b-form-checkbox
              v-model="is_mou"
              switch
              inline
              class="mt-1"
            /> {{ (is_mou) ? 'Ya' : 'Tidak' }}
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="is_mou"
        md="6"
        class="mb-1"
      >
        <p class="mb-0">
          Jenis Item Pendukung
        </p>
        <b-list-group flush>
          <b-list-group-item
            v-for="(data, index) in daftarPendukung"
            :key="index"
            class="d-flex justify-content-between"
          >
            <b-form-checkbox
              :ref="`perangkat-${data.id}`"
              v-model="nonTeknis.perangkat_pendukung"
              :value="data.perangkat_pendukung_id"
              plain
            >
              {{ data.nama }}
            </b-form-checkbox>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan atau keterangan dari data, terutama mengenai ciri khas dari data tersebut.'"
          label="Uraian Item Kegiatan"
          label-for="vi-deskripsi"
        >
          <validation-provider
            #default="{ errors }"
            name="Uraian Item Kegiatan"
            rules="required"
          >
            <b-form-textarea
              id="vi-deskripsi"
              v-model="nonTeknis.uraian"
              rows="4"
              required
              name="deskripsi"
              placeholder="Uraian Item Kegiatan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="nonTeknis.kak_uri"
        md="6"
        class="mb-50"
      >
        <h5>Kerangka Acuan Kerja</h5>
        <p>{{ nonTeknis.kak_uri.substring(nonTeknis.kak_uri.lastIndexOf('/') + 1) }}
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(nonTeknis.kak_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(nonTeknis.kak_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
        </p>
      </b-col>
      <b-col
        v-if="nonTeknis.additional_docs && nonTeknis.additional_docs.length !== 0"
        md="6"
        class="mb-50"
      >
        <h5>Dokumen Tambahan</h5>
        <p
          v-for="(data, index) in nonTeknis.additional_docs"
          :id="`dt-${data.document_id}`"
          :key="index"
        >{{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}
          <b-button
            variant="success"
            size="sm"
            class="mr-25 mb-25"
            @click="viewFile(data.document_uri)"
          >
            <feather-icon
              icon="BookOpenIcon"
              size="14"
            /></b-button>
          <b-link
            :href="getDoc(data.document_uri)"
            class="btn btn-sm btn-info mr-25 mb-25"
            target="_blank"
          >
            <feather-icon
              icon="DownloadIcon"
              size="14"
            /></b-link>
          <b-button
            variant="outline-danger"
            size="sm"
            class="mr-25 mb-25"
            @click="removeFile(data.document_id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="14"
            /></b-button>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf)'"
          label="Kerangka Acuan Kerja (dalam format PDF)"
          label-for="file_kak"
        >
          <b-form-file
            ref="file_kak"
            v-model="file1"
            name="file_kak"
            class="mt-1"
            @input="checkFile"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="`Upload file Dokumen Tambahan (dalam format .pdf)`"
          label="Dokumen Tambahan (dalam format PDF)"
          label-for="file_extra_doc"
        >
          <b-form-file
            ref="file_extra_doc"
            v-model="file2"
            name="file_extra_doc"
            class="mt-1"
            multiple
            @input="checkFile2"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Dokumen Tambahan?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, VBTooltip, BFormFile, BFormInput, BFormTextarea,
  BModal, BCardText, BLink, BButton, BListGroup, BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import VuePdfApp from 'vue-pdf-app'
import AlertModuleDetail from '../detail/AlertModuleDetail.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BFormInput,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BModal,
    BLink,
    BButton,
    BCardText,
    ValidationProvider,
    VuePdfApp,
    AlertModuleDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      nonTeknis: {
        kak_uri: '',
        additional_docs: [],
      },
      errorMsg: '',
      data_utama_id: '',
      non_teknis_id: '',
      nama_item: '',
      total_anggaran: '',
      deskripsi: '',
      file1: null,
      file2: [],
      fileDoc: '',
      toDelData: '',
      daftarPendukung: [],
      dataPendukung: [],
      is_mou: false,
    }
  },
  watch: {
    actionStep() {
      this.checkInformasi()
    },
  },
  mounted() {
    this.getReference()
  },
  methods: {
    getReference() {
      this.$http.get('/clearance/non-teknis/references', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.coreData.data_utama_id,
          tahun_anggaran: this.coreData.year_budget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.daftarPendukung = res.data.data
            this.setReference()
          }
        })
    },
    setReference() {
      this.$http.get('/clearance/non-teknis', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.coreData.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.nonTeknis = res.data.data
            this.non_teknis_id = res.data.data.non_teknis_id

            if (this.nonTeknis.perangkat_pendukung.length !== 0) {
              this.is_mou = true
              this.daftarPendukung.map(dt => {
                if (this.nonTeknis.perangkat_pendukung.includes(dt.nama)) {
                  this.dataPendukung.push(dt.perangkat_pendukung_id)
                }
                return true
              })
              this.nonTeknis.perangkat_pendukung = this.dataPendukung
            }
          }
        })
    },
    checkFile() {
      if (this.file1) {
        if (this.file1.size > 100 * 1024 * 1024) {
          this.errorMsg = `${this.file1.name}: Ukuran Dokumen Terlalu Besar`
        } if (this.file1.type !== 'application/pdf') {
          this.errorMsg = `${this.file1.name}: Jenis Dokumen Tidak Sesuai`
        } else {
          this.errorMsg = ''
        }
      }
    },
    checkFile2() {
      if (this.file2.length !== 0) {
        const notif = []
        let not = false
        for (let i = 0; i < this.file2.length; i += 1) {
          const alert = []
          if (this.file2[i].size > 100 * 1024 * 1024) {
            not = true
            alert.push(`${this.file2[i].name}: Ukuran Dokumen Terlalu Besar`)
          } if (this.file2[i].type !== 'application/pdf') {
            not = true
            alert.push(`${this.file2[i].name}: Jenis Dokumen Tidak Sesuai`)
          }
          if (not) {
            notif.push(alert)
          }
        }
        this.errorMsg = (notif.length !== 0) ? notif : ''
      }
    },
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    removeFile(value) {
      this.toDelData = value
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
    },
    deleteItem() {
      this.$http.post('/clearance/non-teknis/file/delete', {
        document_id: this.toDelData,
        non_teknis_id: this.non_teknis_id,
      }, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById(`dt-${this.toDelData}`).remove()
            this.clearDeleteItem()
          }
        })
    },
    checkInformasi() {
      if (!this.file1 && !this.nonTeknis.kak_uri) {
        const data = {
          status: false,
          msg: 'File KAK Tidak Boleh Kosong',
        }
        this.$emit('step-completed', data)
      } else {
        this.saveNonTeknis()
      }
    },
    saveNonTeknis() {
      this.errorMsg = ''
      document.getElementById('loading-bg').style.display = 'block'
      this.nonTeknis.data_utama_id = Number(this.$route.params.id)
      this.$http.post('/clearance/non-teknis', this.nonTeknis, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
        },
      })
        .then(res => {
          this.non_teknis_id = res.data.data.non_teknis_id
          const status = true

          const promise1 = new Promise(resolve => {
            if (this.file1) {
              const formFile = new FormData()
              formFile.append('clearance_id', this.coreData.clearance_id)
              formFile.append('data_utama_id', this.coreData.data_utama_id)
              formFile.append('non_teknis_id', this.non_teknis_id)
              formFile.append('kak_file', this.file1)

              this.$http.post('/clearance/non-teknis/file/upload', formFile, {
                params: {
                  token: localStorage.getItem('userToken'),
                },
              })
                .then(resp => {
                  if (resp.data.status === 'success') {
                    this.nonTeknis.kak_uri = resp.data.data[0].document_uri
                    resolve(true)
                  } else {
                    const data = {
                      status: false,
                      msg: resp.data.error,
                    }
                    document.getElementById('loading-bg').style.display = 'none'
                    this.errorMsg = resp.data.error
                    this.$emit('step-completed', data)
                    resolve(false)
                  }
                })
                .catch(error => {
                  const data = {
                    status: false,
                    msg: error.response.data.error,
                  }
                  document.getElementById('loading-bg').style.display = 'none'
                  this.errorMsg = error.response.data.error
                  this.$emit('step-completed', data)
                  resolve(false)
                })
            } else {
              resolve(true)
            }
          })

          const promise2 = new Promise(resolve => {
            if (this.file2.length !== 0) {
              for (let i = 0; i < this.file2.length; i += 1) {
                const formFile = new FormData()
                formFile.append('clearance_id', this.coreData.clearance_id)
                formFile.append('data_utama_id', this.coreData.data_utama_id)
                formFile.append('non_teknis_id', this.non_teknis_id)
                formFile.append('additional_files', this.file2[i])

                this.$http.post('/clearance/non-teknis/file/upload', formFile, {
                  params: {
                    token: localStorage.getItem('userToken'),
                  },
                })
                  .then(resp => {
                    if (resp.data.status === 'success') {
                      this.nonTeknis.additional_docs.push(resp.data.data[0])
                      resolve(true)
                    } else {
                      const data = {
                        status: false,
                        msg: resp.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = resp.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    const data = {
                      status: false,
                      msg: error.response.data.error,
                    }
                    document.getElementById('loading-bg').style.display = 'none'
                    this.errorMsg = error.response.data.error
                    this.$emit('step-completed', data)
                    resolve(false)
                  })
              }
            } else {
              resolve(true)
            }
          })

          Promise.all([promise1, promise2, status]).then(values => {
            if (values[0] && values[1] && values[2]) {
              const data = {
                status: true,
                value: values,
                sakti: this.sakti,
                msg: this.coreData,
              }
              document.getElementById('loading-bg').style.display = 'none'
              data.msg.data_utama_id = this.clearanceId
              this.$refs.file_extra_doc.reset()
              this.file2 = []
              this.dataSaved = true
              this.errorMsg = ''
              this.$emit('step-completed', data)
            }
          })
            .catch(error => {
              document.getElementById('loading-bg').style.display = 'none'
              const data = {
                status: true,
                msg: error.response,
              }
              this.$emit('step-completed', data)
            })
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
        })
    },
  },
}
</script>
