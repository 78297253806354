var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan jenis jaringan yang digunakan.'),expression:"'Merupakan jenis jaringan yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis jaringan","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis jaringan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-jenis","required":"","name":"jenis","placeholder":"Jenis jaringan"},model:{value:(_vm.infData.jenis),callback:function ($$v) {_vm.$set(_vm.infData, "jenis", $$v)},expression:"infData.jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan pemilik dari jaringan.'),expression:"'Merupakan pemilik dari jaringan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-kepemilikan"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.dataReferensi.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.kepemilikan),callback:function ($$v) {_vm.$set(_vm.infData, "kepemilikan", $$v)},expression:"infData.kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan nama dari pemilik jaringan.'),expression:"'Merupakan nama dari pemilik jaringan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik","label-for":"vi-nama_pemilik "}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_pemilik","name":"nama_pemilik","placeholder":"Nama Pemilik"},model:{value:(_vm.infData.nama_pemilik),callback:function ($$v) {_vm.$set(_vm.infData, "nama_pemilik", $$v)},expression:"infData.nama_pemilik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan unit kerja/perangkat daerah pengelola jaringan.'),expression:"'Merupakan unit kerja/perangkat daerah pengelola jaringan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit kerja pengelola jaringan","label-for":"vi-unit_kerja"}},[_c('validation-provider',{attrs:{"name":"Unit kerja pengelola jaringan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_kerja","required":"","name":"unit_kerja","placeholder":"Unit kerja pengelola jaringan"},model:{value:(_vm.infData.unit_kerja),callback:function ($$v) {_vm.$set(_vm.infData, "unit_kerja", $$v)},expression:"infData.unit_kerja"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'),expression:"'Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Bandwidth Internet","label-for":"vi-bandwidth "}},[_c('validation-provider',{attrs:{"name":"Bandwidth Internet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"mbps"}},[_c('b-form-input',{attrs:{"id":"vi-bandwidth ","required":"","name":"bandwidth","placeholder":"Bandwidth Internet"},model:{value:(_vm.infData.bandwidth),callback:function ($$v) {_vm.$set(_vm.infData, "bandwidth", $$v)},expression:"infData.bandwidth"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan tipe dari media jaringan.'),expression:"'Merupakan tipe dari media jaringan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Tipe Media Jaringan","label-for":"vi-tipe_media_jaringan"}},[_c('validation-provider',{attrs:{"name":"Tipe Media Jaringan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-tipe_media_jaringan","name":"tipe_media_jaringan","placeholder":"Tipe Media Jaringan"},model:{value:(_vm.infData.tipe_media_jaringan),callback:function ($$v) {_vm.$set(_vm.infData, "tipe_media_jaringan", $$v)},expression:"infData.tipe_media_jaringan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan tipe dari media jaringan.'),expression:"'Merupakan tipe dari media jaringan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Media Lainnya","label-for":"vi-media_lainnya"}},[_c('validation-provider',{attrs:{"name":"Media Lainnya","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-media_lainnya","required":"","name":"media_lainnya","placeholder":"Media Lainnya"},model:{value:(_vm.infData.media_lainnya),callback:function ($$v) {_vm.$set(_vm.infData, "media_lainnya", $$v)},expression:"infData.media_lainnya"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan penjelasan dari jaringan.'),expression:"'Merupakan penjelasan dari jaringan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Jaringan","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Jaringan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Jaringan"},model:{value:(_vm.infData.deskripsi),callback:function ($$v) {_vm.$set(_vm.infData, "deskripsi", $$v)},expression:"infData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }