var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis dari Government cloud yang digunakan (PaaS, IaaS, SaaS, BDaaS, dan SecaaS).'),expression:"'Jenis dari Government cloud yang digunakan (PaaS, IaaS, SaaS, BDaaS, dan SecaaS).'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Komputasi Awan Pemerintahan","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis Komputasi Awan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-jenis","required":"","name":"jenis","options":_vm.daftarJenisCloud,"placeholder":"Jenis Komputasi Awan Pemerintahan","label":"nama"},model:{value:(_vm.infData.jenis),callback:function ($$v) {_vm.$set(_vm.infData, "jenis", $$v)},expression:"infData.jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Status kepemilikan dari Government cloud yang digunakan.'),expression:"'Status kepemilikan dari Government cloud yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-ownership_status"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.dataReferensi.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.kepemilikan),callback:function ($$v) {_vm.$set(_vm.infData, "kepemilikan", $$v)},expression:"infData.kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama pemilik Government cloud yang digunakan.'),expression:"'Nama pemilik Government cloud yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik","label-for":"vi-nama_pemilik"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_pemilik","required":"","name":"nama_pemilik","placeholder":"Nama Pemilik"},model:{value:(_vm.infData.nama_pemilik),callback:function ($$v) {_vm.$set(_vm.infData, "nama_pemilik", $$v)},expression:"infData.nama_pemilik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Biaya yang dikeluarkan pemilik untuk layanan Government cloud.'),expression:"'Biaya yang dikeluarkan pemilik untuk layanan Government cloud.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":("Biaya Layanan " + ((_vm.infData.biaya_layanan) ? ': Rp. ' + Number(_vm.infData.biaya_layanan).toLocaleString() : '')),"label-for":"vi-biaya_layanan"}},[_c('validation-provider',{attrs:{"name":"Biaya Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"Rp."}},[_c('b-form-input',{attrs:{"id":"vi-biaya_layanan","type":"number","required":"","name":"biaya_layanan","placeholder":"Biaya Layanan"},model:{value:(_vm.infData.biaya_layanan),callback:function ($$v) {_vm.$set(_vm.infData, "biaya_layanan", $$v)},expression:"infData.biaya_layanan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit yang melakukan pembangunan dan pengembangan Government cloud.'),expression:"'Unit yang melakukan pembangunan dan pengembangan Government cloud.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Pengembang Komputasi Awan Pemerintahan","label-for":"vi-unit_pengembang"}},[_c('validation-provider',{attrs:{"name":"Unit Pengembang Komputasi Awan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_pengembang","required":"","name":"unit_pengembang","placeholder":"Unit Pengembang Komputasi Awan Pemerintahan"},model:{value:(_vm.infData.unit_pengembang),callback:function ($$v) {_vm.$set(_vm.infData, "unit_pengembang", $$v)},expression:"infData.unit_pengembang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit operasional Government cloud yang digunakan.'),expression:"'Unit operasional Government cloud yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Operasional Komputasi Awan Pemerintahan","label-for":"vi-unit_operasional"}},[_c('validation-provider',{attrs:{"name":"Unit Operasional Komputasi Awan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_operasional","required":"","name":"unit_operasional","placeholder":"Unit Operasional Komputasi Awan Pemerintahan"},model:{value:(_vm.infData.unit_operasional),callback:function ($$v) {_vm.$set(_vm.infData, "unit_operasional", $$v)},expression:"infData.unit_operasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Periode penggunaan layanan Government cloud.'),expression:"'Periode penggunaan layanan Government cloud.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jangka waktu Layanan","label-for":"vi-jangka_waktu_layanan"}},[_c('validation-provider',{attrs:{"name":"Jangka waktu Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"Bulan"}},[_c('b-form-input',{attrs:{"id":"vi-jangka_waktu_layanan","required":"","name":"jangka_waktu_layanan","placeholder":"Jangka waktu Layanan"},model:{value:(_vm.infData.jangka_waktu_layanan),callback:function ($$v) {_vm.$set(_vm.infData, "jangka_waktu_layanan", $$v)},expression:"infData.jangka_waktu_layanan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Penjelasan dari Government cloud yang digunakan.'),expression:"'Penjelasan dari Government cloud yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Komputasi Awan Pemerintahan","label-for":"vi-deskripsi-cloud"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Komputasi Awan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi-cloud","required":"","name":"deskripsi","placeholder":"Deskripsi Komputasi Awan Pemerintahan"},model:{value:(_vm.infData.deskripsi),callback:function ($$v) {_vm.$set(_vm.infData, "deskripsi", $$v)},expression:"infData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }