<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jenis dari security device yang digunakan (Firewall, Intrusion Detection System, Intrusion Prevention System, Proxy, Load Balancer, Wireless intrusion prevention and detection system, Unified Threat Management, atau Network Access Control)'"
        label="Jenis Perangkat Keamanan"
        label-for="vi-jenis"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis Perangkat Keamanan"
          rules="required"
        >
          <v-select
            id="vi-jenis"
            v-model="infData.jenis"
            required
            name="jenis"
            :options="daftarJenisSecurity"
            placeholder="Jenis Perangkat Keamanan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Status kepemilikan dari Security Device yang digunakan (Milik Sendiri; Milik Instansi Pemerintah Lain; Milik BUMN; atau Milik Pihak Ketiga).'"
        label="Kepemilikan"
        label-for="vi-ownership_status"
      >
        <validation-provider
          #default="{ errors }"
          name="Kepemilikan"
          rules="required"
        >
          <v-select
            id="vi-kepemilikan"
            v-model="infData.kepemilikan"
            required
            name="kepemilikan"
            :options="dataReferensi.ref_kepemilikan"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Nama pemilik Security Device yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'"
        label="Nama Pemilik"
        label-for="vi-nama_pemilik"
      >
        <validation-provider
          #default="{ errors }"
          name="Nama Pemilik"
          rules="required"
        >
          <b-form-input
            id="vi-nama_pemilik"
            v-model="infData.nama_pemilik"
            required
            name="nama_pemilik"
            placeholder="Nama Pemilik"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Unit pengelola Security Device yang digunakan.'"
        label="Unit Pengelola Perangkat Keamanan"
        label-for="vi-unit_operasional"
      >
        <validation-provider
          #default="{ errors }"
          name="Unit Pengelola Perangkat Keamanan"
          rules="required"
        >
          <b-form-input
            id="vi-unit_operasional"
            v-model="infData.unit_operasional"
            required
            name="unit_operasional"
            placeholder="Unit Pengelola Perangkat Keamanan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        v-b-tooltip.hover.top="'Penjelasan dari Security Device yang digunakan.'"
        label="Deskripsi Perangkat Jaringan"
        label-for="vi-deskripsi"
      >
        <validation-provider
          #default="{ errors }"
          name="Deskripsi Perangkat Jaringan"
          rules="required"
        >
          <b-form-textarea
            id="vi-deskripsi"
            v-model="infData.deskripsi"
            required
            name="deskripsi"
            placeholder="Deskripsi Perangkat Jaringan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BCol, BFormGroup, VBTooltip, BRow, BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BFormTextarea,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
      daftarJenisSecurity: ['Firewall', 'Intrusion Detection System', 'Intrusion Prevention System', 'Proxy', 'Load Balancer', 'Wireless intrusion prevention and detection system', 'Unified Threat Management', 'Network Access Control'],
    }
  },
}
</script>
