<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jenis sistem penghubung yang digunakan (Sistem penghubung pemerintah / Sistem penghubung non pemerintah).'"
        label="Jenis Sistem Penghubung"
        label-for="vi-jenis"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis Sistem Penghubung"
          rules="required"
        >
          <b-form-input
            id="vi-jenis"
            v-model="infData.jenis"
            required
            name="jenis"
            placeholder="Jenis Sistem Penghubung"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Status kepemilikan dari sistem penghubung yang digunakan (Milik Sendiri; Milik Instansi Pemerintah Lain; Milik BUMN; atau Milik Pihak Ketiga).'"
        label="Kepemilikan"
        label-for="vi-ownership_status"
      >
        <validation-provider
          #default="{ errors }"
          name="Kepemilikan"
          rules="required"
        >
          <v-select
            id="vi-kepemilikan"
            v-model="infData.kepemilikan"
            required
            name="kepemilikan"
            :options="dataReferensi.ref_kepemilikan"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Nama pemilik data storage yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri..'"
        label="Nama Pemilik"
        label-for="vi-nama_pemilik"
      >
        <validation-provider
          #default="{ errors }"
          name="Nama Pemilik"
          rules="required"
        >
          <b-form-input
            id="vi-nama_pemilik"
            v-model="infData.nama_pemilik"
            required
            name="nama_pemilik"
            placeholder="Nama Pemilik"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Nama jaringan intra yang digunakan oleh sistem penghubung, pilihan jaringan intra didapat dari metadata jaringan intra.'"
        label="Nama Jaringan Intra yang digunakan"
        label-for="vi-nama_jaringan"
      >
        <validation-provider
          #default="{ errors }"
          name="Nama Jaringan Intra yang digunakan"
          rules="required"
        >
          <b-form-input
            id="vi-nama_jaringan"
            v-model="infData.nama_jaringan"
            required
            name="nama_jaringan"
            placeholder="Nama Jaringan Intra yang digunakan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Aplikasi yang dihubungkan oleh sistem penghubung, pilihan aplikasi didapat dari metadata aplikasi.'"
        label="Aplikasi yang dihubungkan"
        label-for="vi-aplikasi_terhubung"
      >
        <validation-provider
          #default="{ errors }"
          name="Aplikasi yang dihubungkan"
          rules="required"
        >
          <b-form-input
            id="vi-aplikasi_terhubung"
            v-model="infData.aplikasi_terhubung"
            required
            name="aplikasi_terhubung"
            placeholder="Aplikasi yang dihubungkan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        v-b-tooltip.hover.top="'Berisi deskripsi dari sistem penghubung yang digunakan..'"
        label="Deskripsi Sistem Penghubung"
        label-for="vi-deskripsi"
      >
        <validation-provider
          #default="{ errors }"
          name="Deskripsi Sistem Penghubung"
          rules="required"
        >
          <b-form-textarea
            id="vi-deskripsi"
            v-model="infData.deskripsi"
            required
            name="deskripsi"
            placeholder="Deskripsi Sistem Penghubung"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BCol, BFormGroup, VBTooltip, BRow, BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
    }
  },
}
</script>
