var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis dari Perangkat Lunak yang digunakan.'),expression:"'Jenis dari Perangkat Lunak yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Perangkat Lunak","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis Perangkat Lunak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-jenis","required":"","name":"jenis","options":_vm.daftarJenisSoft,"placeholder":"Pilih Salah Satu","label":"nama"},on:{"input":_vm.updateJenis},model:{value:(_vm.infData.jenis),callback:function ($$v) {_vm.$set(_vm.infData, "jenis", $$v)},expression:"infData.jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("Jenis " + _vm.nama_sistem + " yang digunakan.")),expression:"`Jenis ${nama_sistem} yang digunakan.`",modifiers:{"hover":true,"top":true}}],attrs:{"label":("Jenis " + _vm.nama_sistem),"label-for":"vi-perangkat_lunak"}},[_c('validation-provider',{attrs:{"name":("Jenis " + _vm.nama_sistem),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-perangkat_lunak","required":"","name":"perangkat_lunak","placeholder":("Jenis " + _vm.nama_sistem)},model:{value:(_vm.infData.perangkat_lunak),callback:function ($$v) {_vm.$set(_vm.infData, "perangkat_lunak", $$v)},expression:"infData.perangkat_lunak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis Lisensi Perangkat Lunak yang digunakan.'),expression:"'Jenis Lisensi Perangkat Lunak yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Lisensi","label-for":"vi-license_type"}},[_c('validation-provider',{attrs:{"name":"Jenis Lisensi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-license_type","required":"","name":"license_type","options":_vm.dataReferensi.ref_jenis_lisensi,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.license_type),callback:function ($$v) {_vm.$set(_vm.infData, "license_type", $$v)},expression:"infData.license_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama pemilik dari lisensi perangkat lunak.'),expression:"'Nama pemilik dari lisensi perangkat lunak.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik Lisensi","label-for":"vi-pemilik_lisensi"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik Lisensi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-pemilik_lisensi","required":"","name":"pemilik_lisensi","placeholder":"Nama Pemilik Lisensi"},model:{value:(_vm.infData.pemilik_lisensi),callback:function ($$v) {_vm.$set(_vm.infData, "pemilik_lisensi", $$v)},expression:"infData.pemilik_lisensi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Penjelasan validitas dari lisensi perangkat lunak yang digunakan.'),expression:"'Penjelasan validitas dari lisensi perangkat lunak yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Validitas Lisensi Perangkat Lunak","label-for":"vi-validasi_lisensi"}},[_c('validation-provider',{attrs:{"name":"Validitas Lisensi Perangkat Lunak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-validasi_lisensi","required":"","name":"validasi_lisensi","placeholder":"Validitas Lisensi Perangkat Lunak"},model:{value:(_vm.infData.validasi_lisensi),callback:function ($$v) {_vm.$set(_vm.infData, "validasi_lisensi", $$v)},expression:"infData.validasi_lisensi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Penjelasan dari Perangkat Lunak yang digunakan.'),expression:"'Penjelasan dari Perangkat Lunak yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Perangkat Lunak","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Perangkat Lunak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Perangkat Lunak"},model:{value:(_vm.infData.deskripsi),callback:function ($$v) {_vm.$set(_vm.infData, "deskripsi", $$v)},expression:"infData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }