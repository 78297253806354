var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:("formInfra-new-" + (_vm.appData.id))},[_c('b-form',{attrs:{"id":("form-new-inf-" + (_vm.appData.id))}},[_c('b-card',{staticClass:"border-bottom"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Infrastruktur ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Referensi Arsitektur Domain Infrastruktur SPBE untuk seluruh Infrastruktur yang terkait dengan kegiatan.")])]),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Referensi Arsitektur Domain Infrastruktur sesuai dengan Renja'),expression:"'Referensi Arsitektur Domain Infrastruktur sesuai dengan Renja'",modifiers:{"hover":true,"top":true}}],attrs:{"label-for":"vi-master_infra"}},[_c('validation-provider',{attrs:{"name":"Pilih Nama Infrastruktur atau Tambah Baru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-master_infra","required":"","name":"master_infra","options":_vm.inf_list,"placeholder":"Pilih Nama Infrastruktur atau Tambah Baru","label":"label"},on:{"input":function($event){return _vm.setSelected()}},model:{value:(_vm.master_infra),callback:function ($$v) {_vm.master_infra=$$v},expression:"master_infra"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.master_infra !== '')?_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Domain Infrastruktur","label-for":"vi-inf_domain_id"}},[_c('validation-provider',{attrs:{"name":"Domain Infrastruktur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-inf_domain_id","required":"","name":"inf_domain_id","options":_vm.daftarDomainInfra,"placeholder":"Domain Infrastruktur","label":"nama"},on:{"input":function($event){return _vm.getAreaInfra()}},model:{value:(_vm.inf_domain_id),callback:function ($$v) {_vm.inf_domain_id=$$v},expression:"inf_domain_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3597586665)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Area Infrastruktur","label-for":"vi-inf_area_id"}},[_c('validation-provider',{attrs:{"name":"Area Infrastruktur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-inf_area_id","required":"","name":"inf_area_id","options":_vm.daftarAreaInfra,"placeholder":"Area Infra","label":"nama"},on:{"input":function($event){return _vm.getKategoriInfra()}},model:{value:(_vm.inf_area_id),callback:function ($$v) {_vm.inf_area_id=$$v},expression:"inf_area_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3767320761)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'),expression:"'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kategori Infrastruktur","label-for":"vi-inf_category_id"}},[_c('v-select',{attrs:{"id":"vi-inf_category_id","name":"inf_category_id","options":_vm.daftarKategoriInfra,"placeholder":"Kategori Infrastruktur","label":"nama","disabled":""},model:{value:(_vm.inf_category_id),callback:function ($$v) {_vm.inf_category_id=$$v},expression:"inf_category_id"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("Nama " + (_vm.inf_domain_id.name) + " yang digunakan.")),expression:"`Nama ${inf_domain_id.name} yang digunakan.`",modifiers:{"hover":true,"top":true}}],attrs:{"label":("Nama " + _vm.nama_infra),"label-for":"vi-nama"}},[_c('validation-provider',{attrs:{"name":("Nama " + _vm.nama_infra),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama","required":"","name":"nama","placeholder":("Nama " + _vm.nama_infra)},model:{value:(_vm.nama),callback:function ($$v) {_vm.nama=$$v},expression:"nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4242231417)})],1)],1),(_vm.inf_area_id.area_infra_id === 4)?[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'),expression:"'Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Bandwidth Internet","label-for":"vi-bandwidth "}},[_c('validation-provider',{attrs:{"name":"Bandwidth Internet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"mbps"}},[_c('b-form-input',{attrs:{"id":"vi-bandwidth ","type":"number","required":"","name":"bandwidth","placeholder":"Bandwidth Internet"},model:{value:(_vm.bandwidth),callback:function ($$v) {_vm.bandwidth=$$v},expression:"bandwidth"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3578937004)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan lokasi dari fasilitas.'),expression:"'Merupakan lokasi dari fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Lokasi","label-for":"vi-lokasi"}},[_c('validation-provider',{attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-lokasi","required":"","name":"lokasi","placeholder":"Lokasi"},model:{value:(_vm.lokasi),callback:function ($$v) {_vm.lokasi=$$v},expression:"lokasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3723365173)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan unit pemilik dari fasilitas.'),expression:"'Merupakan unit pemilik dari fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-kepemilikan"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.referenceData.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.kepemilikan),callback:function ($$v) {_vm.kepemilikan=$$v},expression:"kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1316069626)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan unit kerja/perangkat daerah yang bertanggung jawab atas fasilitas.'),expression:"'Merupakan unit kerja/perangkat daerah yang bertanggung jawab atas fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Kerja Penanggung Jawab","label-for":"vi-unit_kerja"}},[_c('validation-provider',{attrs:{"name":"Unit Kerja Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_kerja","required":"","name":"unit_kerja","placeholder":"Unit Kerja Penanggung Jawab"},model:{value:(_vm.unit_kerja),callback:function ($$v) {_vm.unit_kerja=$$v},expression:"unit_kerja"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,958521117)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan klasifikasi tier fasilitas.'),expression:"'Merupakan klasifikasi tier fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Klasifikasi Tier Fasilitas","label-for":"vi-tier"}},[_c('validation-provider',{attrs:{"name":"Klasifikasi Tier Fasilitas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-tier","required":"","name":"tier","placeholder":"Klasifikasi Tier Fasilitas"},model:{value:(_vm.tier),callback:function ($$v) {_vm.tier=$$v},expression:"tier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,67286152)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan tipe pengaman fasilitas.'),expression:"'Merupakan tipe pengaman fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sistem Pengamanan Fasilitas","label-for":"vi-sistem_pengaman"}},[_c('validation-provider',{attrs:{"name":"Sistem Pengamanan Fasilitas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-sistem_pengaman","required":"","name":"sistem_pengaman","placeholder":"Sistem Pengamanan Fasilitas"},model:{value:(_vm.sistem_pengaman),callback:function ($$v) {_vm.sistem_pengaman=$$v},expression:"sistem_pengaman"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3268511899)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Penjelasan dari Pusat Data yang digunakan.'),expression:"'Penjelasan dari Pusat Data yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Pusat Data","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Pusat Data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Pusat Data"},model:{value:(_vm.deskripsi),callback:function ($$v) {_vm.deskripsi=$$v},expression:"deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,267096218)})],1)],1)]:_vm._e(),(_vm.inf_area_id.area_infra_id === 2)?[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama pemilik server yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'),expression:"'Nama pemilik server yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik","label-for":"vi-nama_pemilik"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_pemilik","required":"","name":"nama_pemilik","placeholder":"Nama Pemilik"},model:{value:(_vm.nama_pemilik),callback:function ($$v) {_vm.nama_pemilik=$$v},expression:"nama_pemilik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,990396618)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis penggunaan dari server yang digunakan, jenis penggunaan tersebut a.l.: Web Server; Mail Server; Aplikasi; Database; File Server; Active Directory, keamanan informasi.'),expression:"'Jenis penggunaan dari server yang digunakan, jenis penggunaan tersebut a.l.: Web Server; Mail Server; Aplikasi; Database; File Server; Active Directory, keamanan informasi.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Penggunaan Server","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis Penggunaan Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-jenis","required":"","name":"jenis","options":_vm.daftarJenisServer,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.jenis),callback:function ($$v) {_vm.jenis=$$v},expression:"jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,579514976)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Status kepemilikan dari server yang digunakan'),expression:"'Status kepemilikan dari server yang digunakan'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-ownership_status"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.referenceData.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.kepemilikan),callback:function ($$v) {_vm.kepemilikan=$$v},expression:"kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1316069626)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit pengelola server yang digunakan.'),expression:"'Unit pengelola server yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Pengelola Server","label-for":"vi-unit_operasional"}},[_c('validation-provider',{attrs:{"name":"Unit Pengelola Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_operasional","required":"","name":"unit_operasional","placeholder":"Unit Pengelola Server"},model:{value:(_vm.unit_operasional),callback:function ($$v) {_vm.unit_operasional=$$v},expression:"unit_operasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,877211815)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Lokasi dari perangkat server yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'),expression:"'Lokasi dari perangkat server yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Lokasi Perangkat Keras Server","label-for":"vi-lokasi"}},[_c('validation-provider',{attrs:{"name":"Lokasi Perangkat Keras Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-lokasi","required":"","name":"lokasi","placeholder":"Lokasi Perangkat Keras Server"},model:{value:(_vm.lokasi),callback:function ($$v) {_vm.lokasi=$$v},expression:"lokasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2505680495)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Berisi deskripsi dari perangkat server yang digunakan.'),expression:"'Berisi deskripsi dari perangkat server yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Perangkat Keras Server","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Perangkat Keras Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Perangkat Keras Server"},model:{value:(_vm.deskripsi),callback:function ($$v) {_vm.deskripsi=$$v},expression:"deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2145628691)})],1)],1)]:_vm._e(),(_vm.inf_area_id.area_infra_id === 3)?[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama pemilik Government cloud yang digunakan.'),expression:"'Nama pemilik Government cloud yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik","label-for":"vi-nama_pemilik"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_pemilik","required":"","name":"nama_pemilik","placeholder":"Nama Pemilik"},model:{value:(_vm.nama_pemilik),callback:function ($$v) {_vm.nama_pemilik=$$v},expression:"nama_pemilik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,990396618)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis dari Government cloud yang digunakan (PaaS, IaaS, SaaS, BDaaS, dan SecaaS).'),expression:"'Jenis dari Government cloud yang digunakan (PaaS, IaaS, SaaS, BDaaS, dan SecaaS).'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Komputasi Awan Pemerintahan","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis Komputasi Awan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-jenis","required":"","name":"jenis","options":_vm.daftarJenisCloud,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.jenis),callback:function ($$v) {_vm.jenis=$$v},expression:"jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,61941364)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Status kepemilikan dari Government cloud yang digunakan.'),expression:"'Status kepemilikan dari Government cloud yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-ownership_status"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.referenceData.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.kepemilikan),callback:function ($$v) {_vm.kepemilikan=$$v},expression:"kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1316069626)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit operasional Government cloud yang digunakan.'),expression:"'Unit operasional Government cloud yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Operasional Komputasi Awan Pemerintahan","label-for":"vi-unit_operasional"}},[_c('validation-provider',{attrs:{"name":"Unit Pengelola Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_operasional","required":"","name":"unit_operasional","placeholder":"Unit Pengelola Server"},model:{value:(_vm.unit_operasional),callback:function ($$v) {_vm.unit_operasional=$$v},expression:"unit_operasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,877211815)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit yang melakukan pembangunan dan pengembangan Government cloud.'),expression:"'Unit yang melakukan pembangunan dan pengembangan Government cloud.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Pengembang Komputasi Awan Pemerintahan","label-for":"vi-unit_pengembang"}},[_c('validation-provider',{attrs:{"name":"Unit Pengembang Komputasi Awan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_pengembang","required":"","name":"unit_pengembang","placeholder":"Unit Pengembang Komputasi Awan Pemerintahan"},model:{value:(_vm.unit_pengembang),callback:function ($$v) {_vm.unit_pengembang=$$v},expression:"unit_pengembang"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2767848202)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Penjelasan dari Government cloud yang digunakan.'),expression:"'Penjelasan dari Government cloud yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Komputasi Awan Pemerintahan","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Komputasi Awan Pemerintahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Komputasi Awan Pemerintahan"},model:{value:(_vm.deskripsi),callback:function ($$v) {_vm.deskripsi=$$v},expression:"deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,707058057)})],1)],1)]:_vm._e(),_c('b-col',[_c('b-button',{staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-success"},on:{"click":_vm.simpanInfra}},[_c('span',[_vm._v("Simpan Referensi Infrastruktur")])]),(_vm.dataSaved)?_c('b-button',{staticClass:"mt-0 mt-md-2 ml-50",attrs:{"variant":"flat-success","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon","size":"14"}}),_vm._v(" Referensi Infrastruktur Berhasil Disimpan ")],1):_vm._e()],1)],2):_vm._e(),(_vm.errorMsg)?_c('div',{staticClass:"mt-2"},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error Found ")]),_c('div',{staticClass:"alert-body"},[(typeof _vm.errorMsg === 'object')?_c('ul',_vm._l((_vm.errorMsg),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index)+": "),_vm._l((item),function(child,indexs){return _c('span',{key:indexs},[_vm._v(" "+_vm._s(child)+" ")])})],2)}),0):_c('span',[_vm._v(_vm._s(_vm.errorMsg))])])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }