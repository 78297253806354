<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jenis dari Periferal yang digunakan (Input, Output, Input/Output)'"
        label="Jenis Periferal"
        label-for="vi-jenis"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis Periferal"
          rules="required"
        >
          <v-select
            id="vi-jenis"
            v-model="infData.jenis"
            required
            name="jenis"
            :options="daftarJenisPeriferal"
            placeholder="Jenis Periferal"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Mengacu pada meta data fasilitas'"
        label="Lokasi Penempatan Periferal"
        label-for="vi-lokasi"
      >
        <validation-provider
          #default="{ errors }"
          name="Lokasi Penempatan Periferal"
          rules="required"
        >
          <b-form-input
            id="vi-lokasi"
            v-model="infData.lokasi"
            required
            name="lokasi"
            placeholder="Lokasi Penempatan Periferal"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Unit pengelola Periferal yang digunakan.'"
        label="Unit Pengelola Jaringan"
        label-for="vi-unit_operasional"
      >
        <validation-provider
          #default="{ errors }"
          name="Unit Pengelola Jaringan"
          rules="required"
        >
          <b-form-input
            id="vi-unit_operasional"
            v-model="infData.unit_operasional"
            required
            name="unit_operasional"
            placeholder="Unit Pengelola Jaringan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        v-b-tooltip.hover.top="'Penjelasan dari Periferal yang digunakan.'"
        label="Deskripsi Periferal"
        label-for="vi-deskripsi"
      >
        <validation-provider
          #default="{ errors }"
          name="Deskripsi Periferal"
          rules="required"
        >
          <b-form-textarea
            id="vi-deskripsi"
            v-model="infData.deskripsi"
            required
            name="deskripsi"
            placeholder="Deskripsi Periferal"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BCol, BFormGroup, VBTooltip, BRow, BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BFormTextarea,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
      daftarJenisPeriferal: ['Input', 'Output', 'Input/Output'],
    }
  },
}
</script>
