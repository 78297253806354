var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'),expression:"'Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Bandwidth Internet","label-for":"vi-bandwidth "}},[_c('validation-provider',{attrs:{"name":"Bandwidth Internet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"mbps"}},[_c('b-form-input',{attrs:{"id":"vi-bandwidth ","type":"number","required":"","name":"bandwidth","placeholder":"Bandwidth Internet"},model:{value:(_vm.infData.bandwidth),callback:function ($$v) {_vm.$set(_vm.infData, "bandwidth", $$v)},expression:"infData.bandwidth"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan lokasi dari fasilitas.'),expression:"'Merupakan lokasi dari fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Lokasi","label-for":"vi-lokasi"}},[_c('validation-provider',{attrs:{"name":"Lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-lokasi","required":"","name":"lokasi","placeholder":"Lokasi"},model:{value:(_vm.infData.lokasi),callback:function ($$v) {_vm.$set(_vm.infData, "lokasi", $$v)},expression:"infData.lokasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan unit pemilik dari fasilitas.'),expression:"'Merupakan unit pemilik dari fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-kepemilikan"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.dataReferensi.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.kepemilikan),callback:function ($$v) {_vm.$set(_vm.infData, "kepemilikan", $$v)},expression:"infData.kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan unit kerja/perangkat daerah yang bertanggung jawab atas fasilitas.'),expression:"'Merupakan unit kerja/perangkat daerah yang bertanggung jawab atas fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Kerja Penanggung Jawab","label-for":"vi-unit_kerja"}},[_c('validation-provider',{attrs:{"name":"Unit Kerja Penanggung Jawab","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_kerja","required":"","name":"unit_kerja","placeholder":"Unit Kerja Penanggung Jawab"},model:{value:(_vm.infData.unit_kerja),callback:function ($$v) {_vm.$set(_vm.infData, "unit_kerja", $$v)},expression:"infData.unit_kerja"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan klasifikasi tier fasilitas.'),expression:"'Merupakan klasifikasi tier fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Klasifikasi Tier Fasilitas","label-for":"vi-tier"}},[_c('validation-provider',{attrs:{"name":"Klasifikasi Tier Fasilitas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-tier","required":"","name":"tier","placeholder":"Klasifikasi Tier Fasilitas"},model:{value:(_vm.infData.tier),callback:function ($$v) {_vm.$set(_vm.infData, "tier", $$v)},expression:"infData.tier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Merupakan tipe pengaman fasilitas.'),expression:"'Merupakan tipe pengaman fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Sistem Pengamanan Fasilitas","label-for":"vi-sistem_pengaman"}},[_c('validation-provider',{attrs:{"name":"Sistem Pengamanan Fasilitas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-sistem_pengaman","required":"","name":"sistem_pengaman","placeholder":"Sistem Pengamanan Fasilitas"},model:{value:(_vm.infData.sistem_pengaman),callback:function ($$v) {_vm.$set(_vm.infData, "sistem_pengaman", $$v)},expression:"infData.sistem_pengaman"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }