<template>
  <section id="ubah-data-kegiatan">
    <!--
      2. Aplikasi
      3. Infrastruktur
      6. Data
      7. Non Tekni
    -->
    <ubah-infrastruktur
      v-if="coreData.jenis_pengadaan_id == '2'"
      :core-data="coreData"
    />
    <ubah-aplikasi
      v-if="coreData.jenis_pengadaan_id == '3'"
      :core-data="coreData"
    />
    <ubah-data
      v-if="coreData.jenis_pengadaan_id == '6'"
      :core-data="coreData"
    />
    <ubah-non
      v-if="coreData.jenis_pengadaan_id == '7'"
      :core-data="coreData"
    />
  </section>

</template>

<script>
import UbahData from './ubah/UbahKegiatanData.vue'
import UbahNon from './ubah/UbahKegiatanNon.vue'
import UbahAplikasi from './ubah/UbahKegiatanAplikasi.vue'
import UbahInfrastruktur from './ubah/UbahKegiatanInfrastruktur.vue'

export default {
  components: {
    UbahNon,
    UbahData,
    UbahAplikasi,
    UbahInfrastruktur,
  },
  data() {
    return {
      data_utama_id: '',
      coreData: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role < 99 || role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  created() {
    this.data_utama_id = Number(this.$route.params.id)
    this.$http.get('/clearance/core-data', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.data_utama_id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.coreData = res.data.data
        }
      })
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
