<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Layanan
        </h5>
        <small class="text-muted">Arsitektur Domain Layanan SPBE.</small>
      </b-col>
      <alert-module-detail :doc-data="layananData" />
      <b-col
        v-if="!layananData.nama_layanan"
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Arsitektur Domain Layanan sesuai dengan Renja'"
          label-for="vi-master_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Layanan atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_layanan"
              v-model="master_layanan"
              required
              name="master_layanan"
              :options="referenceData.services_data_list"
              placeholder="Pilih Nama Layanan atau Tambah Baru"
              label="label"
              @input="setSelected"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'"
          label="Domain Layanan"
          label-for="vi-service_domain_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Domain Layanan"
            rules="required"
          >
            <v-select
              id="vi-service_domain_id"
              v-model="service_domain_id"
              required
              name="service_domain_id"
              :options="referenceData.ref_domain_layanan"
              placeholder="Domain Layanan"
              label="nama"
              @input="getAreaLayanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'"
          label="Area Layanan"
          label-for="vi-service_area_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Area Layanan"
            rules="required"
          >
            <v-select
              id="vi-service_area_id"
              v-model="service_area_id"
              required
              name="service_area_id"
              :options="daftarAreaLayanan"
              placeholder="Area Layanan"
              label="nama"
              @input="getKatLayanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'"
          label="Kategori Layanan"
          label-for="vi-service_category"
        >
          <validation-provider
            #default="{ errors }"
            name="Kategori Layanan"
            rules="required"
          >
            <v-select
              v-if="!fungsi_and_sub"
              id="vi-service_category"
              v-model="service_category"
              required
              name="service_category"
              :options="daftarKatLayanan"
              placeholder="Kategori Layanan"
              label="nama"
              @input="getSubKatLayanan"
            />
            <b-input-group
              v-if="fungsi_and_sub"
            >
              <b-form-input
                id="vi-service_category"
                v-model="kategori_layanan_nama"
                required
                name="service_category"
                placeholder="Kategori Layanan"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="unitReset('cat')"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan nama layanan yang dipilih'"
          label="Sub Kategori Layanan"
          label-for="vi-service_sub_category"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub Kategori Layanan"
            rules="required"
          >
            <v-select
              v-if="!sub_fungsi"
              id="vi-service_sub_category"
              v-model="service_sub_category"
              required
              name="service_sub_category"
              :options="daftarSubKatLayanan"
              placeholder="Sub Kategori Layanan"
              label="nama"
              @input="setSubKatLayanan"
            />
            <b-input-group
              v-if="sub_fungsi"
            >
              <b-form-input
                id="vi-service_sub_category"
                v-model="sub_kategori_layanan_nama"
                required
                name="service_sub_category"
                placeholder="Sub Kategori Layanan"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="unitReset('sub')"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="12"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan nama dari layanan yang dihasilkan oleh unit kerja/perangkat daerah.'"
          label="Nama Layanan"
          label-for="vi-nama_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Layanan"
            rules="required"
          >
            <b-form-input
              id="vi-nama_layanan"
              v-model="layananData.nama_layanan"
              required
              name="nama_layanan"
              placeholder="Nama Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan tujuan dari layanan.'"
          label="Tujuan Layanan"
          label-for="vi-tujuan_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Tujuan Layanan"
            rules="required"
          >
            <b-form-input
              id="vi-tujuan_layanan"
              v-model="layananData.tujuan_layanan"
              required
              name="tujuan_layanan"
              placeholder="Tujuan Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan uraian penjelasan dari fungsi-fungsi yang terdapat dalam layanan.'"
          label="Fungsi Layanan"
          label-for="vi-fungsi_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Fungsi Layanan"
            rules="required"
          >
            <b-form-input
              id="vi-fungsi_layanan"
              v-model="layananData.fungsi_layanan"
              required
              name="fungsi_layanan"
              placeholder="Fungsi Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan unit kerja/perangkat daerah yang melaksanakan layanan.'"
          label="Unit Pelaksana"
          label-for="vi-unit_pelaksana"
        >
          <validation-provider
            #default="{ errors }"
            name="Unit Pelaksana"
            rules="required"
          >
            <b-form-input
              id="vi-unit_pelaksana"
              v-model="layananData.unit_pelaksana"
              required
              name="unit_pelaksana"
              placeholder="Unit Pelaksana"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kementerian/lembaga terkait dengan layanan.'"
          label="Pemda / Kementrian / Lembaga terkait"
          label-for="vi-data_instansi_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Pemda / Kementrian / Lembaga terkait"
            rules="required"
          >
            <v-select
              id="vi-data_instansi_id"
              v-model="data_instansi_id"
              required
              multiple
              name="data_instansi_id"
              :options="referenceData.ref_instansi"
              placeholder="Pemda / Kementrian / Lembaga terkait"
              label="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan target pengguna layanan.'"
          label="Target Layanan"
          label-for="vi-target_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Target Layanan"
            rules="required"
          >
            <b-form-input
              id="vi-target_layanan"
              v-model="layananData.target_layanan"
              required
              name="target_layanan"
              placeholder="Target Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan model dari layanan.'"
          label="Metode Layanan"
          label-for="vi-metode_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Metode Layanan"
            rules="required"
          >
            <b-form-textarea
              id="vi-metode_layanan"
              v-model="layananData.metode_layanan"
              rows="4"
              required
              name="metode_layanan"
              placeholder="Metode Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, VBTooltip,
  BInputGroup, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import AlertModuleDetail from '../detail/AlertModuleDetail.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    vSelect,
    AlertModuleDetail,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      layananData: { is_verified: '' },
      clearanceStatus: null,
      referenceData: {
        services_data_list: [],
        ref_area_layanan: [],
        ref_domain_layanan: [],
        ref_kategori_layanan: [],
        ref_sub_kategori_layanan: [],
        ref_instansi: [],
      },
      data_utama_id: '',
      layanan_id: '',
      master_layanan: '',
      service_domain_id: '',
      service_area_id: '',
      service_category: '',
      service_sub_category: '',
      data_instansi_id: [],
      daftarAreaLayanan: [],
      daftarKatLayanan: [],
      daftarSubKatLayanan: [],
      daftarMasterLayanan: { layanan_id: 'new', label: 'Tambah Layanan Baru' },
      daftarMasterKategori: { kategori_layanan_id: 'new', nama: 'Tambah Kategori Layanan Baru' },
      daftarMasterSubKategori: { sub_kategori_layanan_id: 'new', nama: 'Tambah Sub Kategori Layanan Baru' },
      fungsi_and_sub: false,
      sub_fungsi: false,
      kategori_layanan_nama: '',
      sub_kategori_layanan_nama: '',
    }
  },
  watch: {
    actionStep() {
      this.saveLayanan()
    },
  },
  mounted() {
    this.data_utama_id = Number(this.$route.params.id)
    this.getReference()
  },
  methods: {
    getAreaLayanan() {
      this.daftarAreaLayanan.splice(0)
      this.service_area_id = ''

      if (this.service_domain_id && this.service_domain_id.domain_layanan_id) {
        const filterVal = this.service_domain_id.domain_layanan_id
        const refArea = this.referenceData.ref_area_layanan
        refArea.map(contact => {
          if (contact.domain_layanan_id === filterVal) {
            this.daftarAreaLayanan.push(contact)
          }
          return true
        })
      }
    },
    getKatLayanan() {
      this.daftarKatLayanan = [this.daftarMasterKategori]
      this.service_category = ''

      if (this.service_area_id && this.service_area_id.area_layanan_id) {
        const filterVal = this.service_area_id.area_layanan_id
        const refArea = this.referenceData.ref_kategori_layanan
        refArea.map(contact => {
          if (contact.area_layanan_id === filterVal) {
            this.daftarKatLayanan.push(contact)
          }
          return true
        })
      }

      if (this.daftarKatLayanan.length === 1) {
        this.fungsi_and_sub = true
        this.sub_fungsi = true
      }
    },
    getSubKatLayanan() {
      if (!this.service_category) {
        this.getKatLayanan()
      }
      this.daftarSubKatLayanan = [this.daftarMasterSubKategori]
      this.service_sub_category = ''

      if (this.service_category && this.service_category.kategori_layanan_id !== 'new') {
        const filterVal = this.service_category.kategori_layanan_id
        const refArea = this.referenceData.ref_sub_kategori_layanan
        refArea.map(contact => {
          if (contact.kategori_layanan_id === filterVal) {
            this.daftarSubKatLayanan.push(contact)
          }
          return true
        })
      }

      if (this.service_category && this.service_category.kategori_layanan_id === 'new') {
        this.fungsi_and_sub = true
      }

      if (this.daftarSubKatLayanan.length === 1) {
        this.sub_fungsi = true
      }
    },
    setSubKatLayanan() {
      if (this.service_sub_category && this.service_sub_category.sub_kategori_layanan_id === 'new') {
        this.sub_fungsi = true
      }
    },
    unitReset(val) {
      if (val === 'cat') {
        this.fungsi_and_sub = false
        this.sub_fungsi = false
        this.kategori_layanan_nama = ''
        this.sub_kategori_layanan_nama = ''
      }
      if (val === 'sub') {
        this.sub_fungsi = false
        this.sub_kategori_layanan_nama = ''
      }
    },
    getReference() {
      this.$http.get('/clearance/services-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            if (res.data.data.services_data_list) {
              const pics = [this.daftarMasterLayanan]
              res.data.data.services_data_list.map(value => {
                const pic = value
                pic.label = `${value.nama_layanan} / ${value.domain_layanan} / ${value.area_layanan}`
                pics.push(pic)
                return true
              })
              this.referenceData.services_data_list = pics
            }

            this.setReference()
          }
        })
    },
    setSelected() {
      if (this.master_layanan.layanan_id !== 'new') {
        this.layananData = this.master_layanan
        this.service_domain_id = {
          domain_layanan_id: this.layananData.domain_layanan_id,
          nama: this.layananData.domain_layanan,
        }
        this.getAreaLayanan()

        if (this.layananData.area_layanan_id) {
          this.service_area_id = {
            area_layanan_id: this.layananData.area_layanan_id,
            nama: this.layananData.area_layanan,
          }
          this.getKatLayanan()
        }

        if (this.layananData.kategori_layanan_id) {
          this.service_category = {
            kategori_layanan_id: this.layananData.kategori_layanan_id,
            nama: this.layananData.kategori_layanan,
          }
          this.getSubKatLayanan()
        }

        if (this.layananData.sub_kategori_layanan_id) {
          this.service_sub_category = {
            sub_kategori_layanan_id: this.layananData.sub_kategori_layanan_id,
            nama: this.layananData.sub_kategori_layanan,
          }
        }
        this.data_instansi_id = []
        this.layananData.instansi_terkait.map(value => this.data_instansi_id.push(value))
      }
    },
    setReference() {
      this.$http.get('/clearance/services-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.layananData = res.data.data

            this.service_domain_id = {
              domain_layanan_id: this.layananData.domain_layanan_id,
              nama: this.layananData.domain_layanan,
            }
            this.getAreaLayanan()

            if (this.layananData.area_layanan_id) {
              this.service_area_id = {
                area_layanan_id: this.layananData.area_layanan_id,
                nama: this.layananData.area_layanan,
              }
              this.getKatLayanan()
            }

            if (this.layananData.kategori_layanan_id) {
              this.service_category = {
                kategori_layanan_id: this.layananData.kategori_layanan_id,
                nama: this.layananData.kategori_layanan,
              }
              this.fungsi_and_sub = false
              this.getSubKatLayanan()
            }

            if (this.layananData.sub_kategori_layanan_id) {
              this.service_sub_category = {
                sub_kategori_layanan_id: this.layananData.sub_kategori_layanan_id,
                nama: this.layananData.sub_kategori_layanan,
              }
              this.sub_fungsi = false
            }
            this.data_instansi_id = this.layananData.instansi_terkait
          }
        })
    },
    saveLayanan() {
      document.getElementById('loading-bg').style.display = 'block'
      const listgov = []
      this.data_instansi_id.map(value => listgov.push(value.instansi_id))

      const metaUtama = {
        data_utama_id: this.data_utama_id,
        instansi_id: this.layananData.instansi_id,
        domain_layanan_id: this.service_domain_id ? this.service_domain_id.domain_layanan_id : 1,
        area_layanan_id: this.service_area_id ? this.service_area_id.area_layanan_id : 1,
        nama_layanan: this.layananData.nama_layanan,
        tujuan_layanan: this.layananData.tujuan_layanan,
        fungsi_layanan: this.layananData.fungsi_layanan,
        unit_pelaksana: this.layananData.unit_pelaksana,
        instansi_terkait: listgov,
        target_layanan: this.layananData.target_layanan,
        metode_layanan: this.layananData.metode_layanan,
      }
      if (this.layananData.layanan_id !== '') {
        metaUtama.layanan_id = this.layananData.layanan_id
      }
      if (this.service_category && this.service_category.kategori_layanan_id !== 'new') {
        metaUtama.kategori_layanan_id = this.service_category.kategori_layanan_id
      }
      if (this.service_sub_category && this.service_sub_category.sub_kategori_layanan_id !== 'new') {
        metaUtama.sub_kategori_layanan_id = this.service_sub_category.sub_kategori_layanan_id
      }
      if (this.kategori_layanan_nama) {
        metaUtama.kategori_layanan_nama = this.kategori_layanan_nama
      }
      if (this.sub_kategori_layanan_nama) {
        metaUtama.sub_kategori_layanan_nama = this.sub_kategori_layanan_nama
      }
      this.$http.post('/clearance/services-data', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
