var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis sistem penghubung yang digunakan (Sistem penghubung pemerintah / Sistem penghubung non pemerintah).'),expression:"'Jenis sistem penghubung yang digunakan (Sistem penghubung pemerintah / Sistem penghubung non pemerintah).'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Sistem Penghubung","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis Sistem Penghubung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-jenis","required":"","name":"jenis","placeholder":"Jenis Sistem Penghubung"},model:{value:(_vm.infData.jenis),callback:function ($$v) {_vm.$set(_vm.infData, "jenis", $$v)},expression:"infData.jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Status kepemilikan dari sistem penghubung yang digunakan (Milik Sendiri; Milik Instansi Pemerintah Lain; Milik BUMN; atau Milik Pihak Ketiga).'),expression:"'Status kepemilikan dari sistem penghubung yang digunakan (Milik Sendiri; Milik Instansi Pemerintah Lain; Milik BUMN; atau Milik Pihak Ketiga).'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-ownership_status"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.dataReferensi.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.kepemilikan),callback:function ($$v) {_vm.$set(_vm.infData, "kepemilikan", $$v)},expression:"infData.kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama pemilik data storage yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri..'),expression:"'Nama pemilik data storage yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri..'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik","label-for":"vi-nama_pemilik"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_pemilik","required":"","name":"nama_pemilik","placeholder":"Nama Pemilik"},model:{value:(_vm.infData.nama_pemilik),callback:function ($$v) {_vm.$set(_vm.infData, "nama_pemilik", $$v)},expression:"infData.nama_pemilik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama jaringan intra yang digunakan oleh sistem penghubung, pilihan jaringan intra didapat dari metadata jaringan intra.'),expression:"'Nama jaringan intra yang digunakan oleh sistem penghubung, pilihan jaringan intra didapat dari metadata jaringan intra.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Jaringan Intra yang digunakan","label-for":"vi-nama_jaringan"}},[_c('validation-provider',{attrs:{"name":"Nama Jaringan Intra yang digunakan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_jaringan","required":"","name":"nama_jaringan","placeholder":"Nama Jaringan Intra yang digunakan"},model:{value:(_vm.infData.nama_jaringan),callback:function ($$v) {_vm.$set(_vm.infData, "nama_jaringan", $$v)},expression:"infData.nama_jaringan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Aplikasi yang dihubungkan oleh sistem penghubung, pilihan aplikasi didapat dari metadata aplikasi.'),expression:"'Aplikasi yang dihubungkan oleh sistem penghubung, pilihan aplikasi didapat dari metadata aplikasi.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Aplikasi yang dihubungkan","label-for":"vi-aplikasi_terhubung"}},[_c('validation-provider',{attrs:{"name":"Aplikasi yang dihubungkan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-aplikasi_terhubung","required":"","name":"aplikasi_terhubung","placeholder":"Aplikasi yang dihubungkan"},model:{value:(_vm.infData.aplikasi_terhubung),callback:function ($$v) {_vm.$set(_vm.infData, "aplikasi_terhubung", $$v)},expression:"infData.aplikasi_terhubung"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Berisi deskripsi dari sistem penghubung yang digunakan..'),expression:"'Berisi deskripsi dari sistem penghubung yang digunakan..'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Sistem Penghubung","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Sistem Penghubung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Sistem Penghubung"},model:{value:(_vm.infData.deskripsi),callback:function ($$v) {_vm.$set(_vm.infData, "deskripsi", $$v)},expression:"infData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }