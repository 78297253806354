var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis penggunaan dari server yang digunakan, jenis penggunaan tersebut a.l.: Web Server; Mail Server; Aplikasi; Database; File Server; Active Directory, keamanan informasi.'),expression:"'Jenis penggunaan dari server yang digunakan, jenis penggunaan tersebut a.l.: Web Server; Mail Server; Aplikasi; Database; File Server; Active Directory, keamanan informasi.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Penggunaan Server","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis Penggunaan Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-jenis","required":"","name":"jenis","options":_vm.daftarJenisServer,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.jenis),callback:function ($$v) {_vm.$set(_vm.infData, "jenis", $$v)},expression:"infData.jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Status kepemilikan dari server yang digunakan'),expression:"'Status kepemilikan dari server yang digunakan'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-ownership_status"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.dataReferensi.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.kepemilikan),callback:function ($$v) {_vm.$set(_vm.infData, "kepemilikan", $$v)},expression:"infData.kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama pemilik server yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'),expression:"'Nama pemilik server yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik","label-for":"vi-nama_pemilik"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_pemilik","required":"","name":"nama_pemilik","placeholder":"Nama Pemilik"},model:{value:(_vm.infData.nama_pemilik),callback:function ($$v) {_vm.$set(_vm.infData, "nama_pemilik", $$v)},expression:"infData.nama_pemilik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit pengelola server yang digunakan.'),expression:"'Unit pengelola server yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Pengelola Server","label-for":"vi-unit_operasional"}},[_c('validation-provider',{attrs:{"name":"Unit Pengelola Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_operasional","required":"","name":"unit_operasional","placeholder":"Unit Pengelola Server"},model:{value:(_vm.infData.unit_operasional),callback:function ($$v) {_vm.$set(_vm.infData, "unit_operasional", $$v)},expression:"infData.unit_operasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Lokasi dari perangkat server yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'),expression:"'Lokasi dari perangkat server yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Lokasi Perangkat Keras Server","label-for":"vi-lokasi"}},[_c('validation-provider',{attrs:{"name":"Lokasi Perangkat Keras Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-lokasi","required":"","name":"lokasi","placeholder":"Lokasi Perangkat Keras Server"},model:{value:(_vm.infData.lokasi),callback:function ($$v) {_vm.$set(_vm.infData, "lokasi", $$v)},expression:"infData.lokasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Perangkat lunak yang digunakan oleh server, pilihan perangkat lunak didapat dari metadata perangkat lunak.'),expression:"'Perangkat lunak yang digunakan oleh server, pilihan perangkat lunak didapat dari metadata perangkat lunak.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Perangkat Lunak yang digunakan","label-for":"vi-perangkat_lunak"}},[_c('validation-provider',{attrs:{"name":"Perangkat Lunak yang digunakan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-perangkat_lunak","required":"","name":"perangkat_lunak","placeholder":"Perangkat Lunak yang digunakan"},model:{value:(_vm.infData.perangkat_lunak),callback:function ($$v) {_vm.$set(_vm.infData, "perangkat_lunak", $$v)},expression:"infData.perangkat_lunak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Kapasitas memori atau Random Access Memory (RAM) yang digunakan oleh server, satuan kapasitas memori dalam GigaByte (GB).'),expression:"'Kapasitas memori atau Random Access Memory (RAM) yang digunakan oleh server, satuan kapasitas memori dalam GigaByte (GB).'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kapasitas memori","label-for":"vi-kapasitas_memori"}},[_c('validation-provider',{attrs:{"name":"Kapasitas memori","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"GB"}},[_c('b-form-input',{attrs:{"id":"vi-kapasitas_memori","required":"","name":"kapasitas_memori","placeholder":"Kapasitas memori"},model:{value:(_vm.infData.kapasitas_memori),callback:function ($$v) {_vm.$set(_vm.infData, "kapasitas_memori", $$v)},expression:"infData.kapasitas_memori"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis teknologi prosesor yang digunakan oleh server, a.l.: High End, Mid End, Low End.'),expression:"'Jenis teknologi prosesor yang digunakan oleh server, a.l.: High End, Mid End, Low End.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Teknologi Prosesor","label-for":"vi-processor_type"}},[_c('validation-provider',{attrs:{"name":"Jenis Teknologi Prosesor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-processor_type","required":"","name":"processor_type","options":_vm.dataReferensi.ref_jenis_prosesor,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.processor_type),callback:function ($$v) {_vm.$set(_vm.infData, "processor_type", $$v)},expression:"infData.processor_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jumlah kapasitas penyimpanan pada server, satuan kapasitas penyimpanan dalam Gigabyte (GB).'),expression:"'Jumlah kapasitas penyimpanan pada server, satuan kapasitas penyimpanan dalam Gigabyte (GB).'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jumlah Kapasitas Penyimpanan","label-for":"vi-kapasitas_penyimpanan"}},[_c('validation-provider',{attrs:{"name":"Jumlah Kapasitas Penyimpanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"GB"}},[_c('b-form-input',{attrs:{"id":"vi-kapasitas_penyimpanan","required":"","name":"kapasitas_penyimpanan","placeholder":"Jumlah Kapasitas Penyimpanan Pada Server"},model:{value:(_vm.infData.kapasitas_penyimpanan),callback:function ($$v) {_vm.$set(_vm.infData, "kapasitas_penyimpanan", $$v)},expression:"infData.kapasitas_penyimpanan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Teknik penyimpanan yang digunakan pada server (RAID 1, RAID 3, RAID 5, atau non-RAID.'),expression:"'Teknik penyimpanan yang digunakan pada server (RAID 1, RAID 3, RAID 5, atau non-RAID.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Teknik Penyimpanan","label-for":"vi-storage_technic"}},[_c('validation-provider',{attrs:{"name":"Teknik Penyimpanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-storage_technic","required":"","name":"storage_technic","options":_vm.dataReferensi.ref_teknik_penyimpanan,"placeholder":"Teknik Penyimpanan","label":"nama"},model:{value:(_vm.infData.storage_technic),callback:function ($$v) {_vm.$set(_vm.infData, "storage_technic", $$v)},expression:"infData.storage_technic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Berisi deskripsi dari perangkat server yang digunakan.'),expression:"'Berisi deskripsi dari perangkat server yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Perangkat Keras Server","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Perangkat Keras Server","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Perangkat Keras Server"},model:{value:(_vm.infData.deskripsi),callback:function ($$v) {_vm.$set(_vm.infData, "deskripsi", $$v)},expression:"infData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }