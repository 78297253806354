<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jenis penggunaan dari server yang digunakan, jenis penggunaan tersebut a.l.: Web Server; Mail Server; Aplikasi; Database; File Server; Active Directory, keamanan informasi.'"
        label="Jenis Penggunaan Server"
        label-for="vi-jenis"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis Penggunaan Server"
          rules="required"
        >
          <v-select
            id="vi-jenis"
            v-model="infData.jenis"
            required
            name="jenis"
            :options="daftarJenisServer"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Status kepemilikan dari server yang digunakan'"
        label="Kepemilikan"
        label-for="vi-ownership_status"
      >
        <validation-provider
          #default="{ errors }"
          name="Kepemilikan"
          rules="required"
        >
          <v-select
            id="vi-kepemilikan"
            v-model="infData.kepemilikan"
            required
            name="kepemilikan"
            :options="dataReferensi.ref_kepemilikan"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Nama pemilik server yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'"
        label="Nama Pemilik"
        label-for="vi-nama_pemilik"
      >
        <validation-provider
          #default="{ errors }"
          name="Nama Pemilik"
          rules="required"
        >
          <b-form-input
            id="vi-nama_pemilik"
            v-model="infData.nama_pemilik"
            required
            name="nama_pemilik"
            placeholder="Nama Pemilik"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Unit pengelola server yang digunakan.'"
        label="Unit Pengelola Server"
        label-for="vi-unit_operasional"
      >
        <validation-provider
          #default="{ errors }"
          name="Unit Pengelola Server"
          rules="required"
        >
          <b-form-input
            id="vi-unit_operasional"
            v-model="infData.unit_operasional"
            required
            name="unit_operasional"
            placeholder="Unit Pengelola Server"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Lokasi dari perangkat server yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'"
        label="Lokasi Perangkat Keras Server"
        label-for="vi-lokasi"
      >
        <validation-provider
          #default="{ errors }"
          name="Lokasi Perangkat Keras Server"
          rules="required"
        >
          <b-form-input
            id="vi-lokasi"
            v-model="infData.lokasi"
            required
            name="lokasi"
            placeholder="Lokasi Perangkat Keras Server"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Perangkat lunak yang digunakan oleh server, pilihan perangkat lunak didapat dari metadata perangkat lunak.'"
        label="Perangkat Lunak yang digunakan"
        label-for="vi-perangkat_lunak"
      >
        <validation-provider
          #default="{ errors }"
          name="Perangkat Lunak yang digunakan"
          rules="required"
        >
          <b-form-input
            id="vi-perangkat_lunak"
            v-model="infData.perangkat_lunak"
            required
            name="perangkat_lunak"
            placeholder="Perangkat Lunak yang digunakan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Kapasitas memori atau Random Access Memory (RAM) yang digunakan oleh server, satuan kapasitas memori dalam GigaByte (GB).'"
        label="Kapasitas memori"
        label-for="vi-kapasitas_memori"
      >
        <validation-provider
          #default="{ errors }"
          name="Kapasitas memori"
          rules="required"
        >
          <b-input-group append="GB">
            <b-form-input
              id="vi-kapasitas_memori"
              v-model="infData.kapasitas_memori"
              required
              name="kapasitas_memori"
              placeholder="Kapasitas memori"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jenis teknologi prosesor yang digunakan oleh server, a.l.: High End, Mid End, Low End.'"
        label="Jenis Teknologi Prosesor"
        label-for="vi-processor_type"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis Teknologi Prosesor"
          rules="required"
        >
          <v-select
            id="vi-processor_type"
            v-model="infData.processor_type"
            required
            name="processor_type"
            :options="dataReferensi.ref_jenis_prosesor"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jumlah kapasitas penyimpanan pada server, satuan kapasitas penyimpanan dalam Gigabyte (GB).'"
        label="Jumlah Kapasitas Penyimpanan"
        label-for="vi-kapasitas_penyimpanan"
      >
        <validation-provider
          #default="{ errors }"
          name="Jumlah Kapasitas Penyimpanan"
          rules="required"
        >
          <b-input-group append="GB">
            <b-form-input
              id="vi-kapasitas_penyimpanan"
              v-model="infData.kapasitas_penyimpanan"
              required
              name="kapasitas_penyimpanan"
              placeholder="Jumlah Kapasitas Penyimpanan Pada Server"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Teknik penyimpanan yang digunakan pada server (RAID 1, RAID 3, RAID 5, atau non-RAID.'"
        label="Teknik Penyimpanan"
        label-for="vi-storage_technic"
      >
        <validation-provider
          #default="{ errors }"
          name="Teknik Penyimpanan"
          rules="required"
        >
          <v-select
            id="vi-storage_technic"
            v-model="infData.storage_technic"
            required
            name="storage_technic"
            :options="dataReferensi.ref_teknik_penyimpanan"
            placeholder="Teknik Penyimpanan"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        v-b-tooltip.hover.top="'Berisi deskripsi dari perangkat server yang digunakan.'"
        label="Deskripsi Perangkat Keras Server"
        label-for="vi-deskripsi"
      >
        <validation-provider
          #default="{ errors }"
          name="Deskripsi Perangkat Keras Server"
          rules="required"
        >
          <b-form-textarea
            id="vi-deskripsi"
            v-model="infData.deskripsi"
            required
            name="deskripsi"
            placeholder="Deskripsi Perangkat Keras Server"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BCol, BFormGroup, VBTooltip, BRow, BFormTextarea, BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BFormTextarea,
    BInputGroup,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
      daftarJenisServer: ['Web Server', 'Mail Server', 'Aplikasi', 'Database', 'File Server', 'Active Directory', 'Keamanan Informasi'],
      daftarJenisProcTec: ['High End', 'Mid End', 'Low End'],
      daftarJenisStorTec: ['RAID 1', 'RAID 2', 'RAID 3', 'non-RAID'],
    }
  },
}
</script>
