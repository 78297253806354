var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis dari Periferal yang digunakan (Input, Output, Input/Output)'),expression:"'Jenis dari Periferal yang digunakan (Input, Output, Input/Output)'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Periferal","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis Periferal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-jenis","required":"","name":"jenis","options":_vm.daftarJenisPeriferal,"placeholder":"Jenis Periferal","label":"nama"},model:{value:(_vm.infData.jenis),callback:function ($$v) {_vm.$set(_vm.infData, "jenis", $$v)},expression:"infData.jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Mengacu pada meta data fasilitas'),expression:"'Mengacu pada meta data fasilitas'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Lokasi Penempatan Periferal","label-for":"vi-lokasi"}},[_c('validation-provider',{attrs:{"name":"Lokasi Penempatan Periferal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-lokasi","required":"","name":"lokasi","placeholder":"Lokasi Penempatan Periferal"},model:{value:(_vm.infData.lokasi),callback:function ($$v) {_vm.$set(_vm.infData, "lokasi", $$v)},expression:"infData.lokasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit pengelola Periferal yang digunakan.'),expression:"'Unit pengelola Periferal yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Pengelola Jaringan","label-for":"vi-unit_operasional"}},[_c('validation-provider',{attrs:{"name":"Unit Pengelola Jaringan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_operasional","required":"","name":"unit_operasional","placeholder":"Unit Pengelola Jaringan"},model:{value:(_vm.infData.unit_operasional),callback:function ($$v) {_vm.$set(_vm.infData, "unit_operasional", $$v)},expression:"infData.unit_operasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Penjelasan dari Periferal yang digunakan.'),expression:"'Penjelasan dari Periferal yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Periferal","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Periferal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Periferal"},model:{value:(_vm.infData.deskripsi),callback:function ($$v) {_vm.$set(_vm.infData, "deskripsi", $$v)},expression:"infData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }