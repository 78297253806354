var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Data yang disimpan pada media penyimpanan data, pilihan data yang digunakan didapat dari metadata data.'),expression:"'Data yang disimpan pada media penyimpanan data, pilihan data yang digunakan didapat dari metadata data.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Data Yang Disimpan","label-for":"vi-used_data_id"}},[_c('validation-provider',{attrs:{"name":"Data Yang Disimpan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-data_tematik_id","required":"","disabled":"","name":"data_tematik_id","options":_vm.dataReferensi.ref_data_tematik,"placeholder":"Data yang digunakan","label":"nama"},model:{value:(_vm.infData.data_tematik_id),callback:function ($$v) {_vm.$set(_vm.infData, "data_tematik_id", $$v)},expression:"infData.data_tematik_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Status kepemilikan dari data storage yang digunakan.'),expression:"'Status kepemilikan dari data storage yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-ownership_status"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.dataReferensi.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.kepemilikan),callback:function ($$v) {_vm.$set(_vm.infData, "kepemilikan", $$v)},expression:"infData.kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama pemilik data storage yang digunakan.'),expression:"'Nama pemilik data storage yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik","label-for":"vi-nama_pemilik"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_pemilik","required":"","name":"nama_pemilik","placeholder":"Nama Pemilik"},model:{value:(_vm.infData.nama_pemilik),callback:function ($$v) {_vm.$set(_vm.infData, "nama_pemilik", $$v)},expression:"infData.nama_pemilik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit pengelola data storage yang digunakan.'),expression:"'Unit pengelola data storage yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Pengelola Perangkat Keras Media Penyimpanan","label-for":"vi-unit_operasional"}},[_c('validation-provider',{attrs:{"name":"Unit Pengelola Perangkat Keras Media Penyimpanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_operasional","required":"","name":"unit_operasional","placeholder":"Unit Pengelola Perangkat Keras Media Penyimpanan"},model:{value:(_vm.infData.unit_operasional),callback:function ($$v) {_vm.$set(_vm.infData, "unit_operasional", $$v)},expression:"infData.unit_operasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Lokasi dari data storage yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'),expression:"'Lokasi dari data storage yang digunakan, pilihan lokasi didapat dari metadata fasilitas.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Lokasi Perangkat Keras Media Penyimpanan","label-for":"vi-lokasi"}},[_c('validation-provider',{attrs:{"name":"Lokasi Perangkat Keras Media Penyimpanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-lokasi","required":"","name":"lokasi","placeholder":"Lokasi Perangkat Keras Media Penyimpanan"},model:{value:(_vm.infData.lokasi),callback:function ($$v) {_vm.$set(_vm.infData, "lokasi", $$v)},expression:"infData.lokasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Perangkat lunak yang digunakan oleh data storage.'),expression:"'Perangkat lunak yang digunakan oleh data storage.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Perangkat Lunak yang digunakan","label-for":"vi-perangkat_lunak"}},[_c('validation-provider',{attrs:{"name":"Perangkat Lunak yang digunakan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-perangkat_lunak","required":"","name":"perangkat_lunak","placeholder":"Perangkat Lunak yang digunakan"},model:{value:(_vm.infData.perangkat_lunak),callback:function ($$v) {_vm.$set(_vm.infData, "perangkat_lunak", $$v)},expression:"infData.perangkat_lunak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jumlah kapasitas penyimpanan pada data storage, satuan kapasitas penyimpanan dalam Gigabyte (GB).'),expression:"'Jumlah kapasitas penyimpanan pada data storage, satuan kapasitas penyimpanan dalam Gigabyte (GB).'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kapasitas Penyimpanan","label-for":"vi-kapasitas_penyimpanan"}},[_c('validation-provider',{attrs:{"name":"Kapasitas Penyimpanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"GB"}},[_c('b-form-input',{attrs:{"id":"vi-kapasitas_penyimpanan","required":"","name":"kapasitas_penyimpanan","placeholder":"Kapasitas Penyimpanan"},model:{value:(_vm.infData.kapasitas_penyimpanan),callback:function ($$v) {_vm.$set(_vm.infData, "kapasitas_penyimpanan", $$v)},expression:"infData.kapasitas_penyimpanan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Metode akses data sharing yang digunakan pada data storage (Direct Attached Storage/DAS atau Network Attached Storage/NAS)'),expression:"'Metode akses data sharing yang digunakan pada data storage (Direct Attached Storage/DAS atau Network Attached Storage/NAS)'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Metode Akses Data Sharing","label-for":"vi-metode_akses_penyimpanan"}},[_c('validation-provider',{attrs:{"name":"Metode Akses Data Sharing","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-metode_akses_penyimpanan","required":"","name":"metode_akses_penyimpanan","options":_vm.daftarJenisShare,"placeholder":"Metode Akses Data Sharing","label":"nama"},model:{value:(_vm.infData.metode_akses_penyimpanan),callback:function ($$v) {_vm.$set(_vm.infData, "metode_akses_penyimpanan", $$v)},expression:"infData.metode_akses_penyimpanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Berisi deskripsi dari data storage yang digunakan.'),expression:"'Berisi deskripsi dari data storage yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Data Storage","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Data Storage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Data Storage"},model:{value:(_vm.infData.deskripsi),callback:function ($$v) {_vm.$set(_vm.infData, "deskripsi", $$v)},expression:"infData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }