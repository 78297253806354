<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jenis dari Government cloud yang digunakan (PaaS, IaaS, SaaS, BDaaS, dan SecaaS).'"
        label="Jenis Komputasi Awan Pemerintahan"
        label-for="vi-jenis"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis Komputasi Awan Pemerintahan"
          rules="required"
        >
          <v-select
            id="vi-jenis"
            v-model="infData.jenis"
            required
            name="jenis"
            :options="daftarJenisCloud"
            placeholder="Jenis Komputasi Awan Pemerintahan"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Status kepemilikan dari Government cloud yang digunakan.'"
        label="Kepemilikan"
        label-for="vi-ownership_status"
      >
        <validation-provider
          #default="{ errors }"
          name="Kepemilikan"
          rules="required"
        >
          <v-select
            id="vi-kepemilikan"
            v-model="infData.kepemilikan"
            required
            name="kepemilikan"
            :options="dataReferensi.ref_kepemilikan"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Nama pemilik Government cloud yang digunakan.'"
        label="Nama Pemilik"
        label-for="vi-nama_pemilik"
      >
        <validation-provider
          #default="{ errors }"
          name="Nama Pemilik"
          rules="required"
        >
          <b-form-input
            id="vi-nama_pemilik"
            v-model="infData.nama_pemilik"
            required
            name="nama_pemilik"
            placeholder="Nama Pemilik"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Biaya yang dikeluarkan pemilik untuk layanan Government cloud.'"
        :label="`Biaya Layanan ${(infData.biaya_layanan) ? ': Rp. ' + Number(infData.biaya_layanan).toLocaleString() : ''}`"
        label-for="vi-biaya_layanan"
      >
        <validation-provider
          #default="{ errors }"
          name="Biaya Layanan"
          rules="required"
        >
          <b-input-group prepend="Rp.">
            <b-form-input
              id="vi-biaya_layanan"
              v-model="infData.biaya_layanan"
              type="number"
              required
              name="biaya_layanan"
              placeholder="Biaya Layanan"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Unit yang melakukan pembangunan dan pengembangan Government cloud.'"
        label="Unit Pengembang Komputasi Awan Pemerintahan"
        label-for="vi-unit_pengembang"
      >
        <validation-provider
          #default="{ errors }"
          name="Unit Pengembang Komputasi Awan Pemerintahan"
          rules="required"
        >
          <b-form-input
            id="vi-unit_pengembang"
            v-model="infData.unit_pengembang"
            required
            name="unit_pengembang"
            placeholder="Unit Pengembang Komputasi Awan Pemerintahan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Unit operasional Government cloud yang digunakan.'"
        label="Unit Operasional Komputasi Awan Pemerintahan"
        label-for="vi-unit_operasional"
      >
        <validation-provider
          #default="{ errors }"
          name="Unit Operasional Komputasi Awan Pemerintahan"
          rules="required"
        >
          <b-form-input
            id="vi-unit_operasional"
            v-model="infData.unit_operasional"
            required
            name="unit_operasional"
            placeholder="Unit Operasional Komputasi Awan Pemerintahan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Periode penggunaan layanan Government cloud.'"
        label="Jangka waktu Layanan"
        label-for="vi-jangka_waktu_layanan"
      >
        <validation-provider
          #default="{ errors }"
          name="Jangka waktu Layanan"
          rules="required"
        >
          <b-input-group append="Bulan">
            <b-form-input
              id="vi-jangka_waktu_layanan"
              v-model="infData.jangka_waktu_layanan"
              required
              name="jangka_waktu_layanan"
              placeholder="Jangka waktu Layanan"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        v-b-tooltip.hover.top="'Penjelasan dari Government cloud yang digunakan.'"
        label="Deskripsi Komputasi Awan Pemerintahan"
        label-for="vi-deskripsi-cloud"
      >
        <validation-provider
          #default="{ errors }"
          name="Deskripsi Komputasi Awan Pemerintahan"
          rules="required"
        >
          <b-form-textarea
            id="vi-deskripsi-cloud"
            v-model="infData.deskripsi"
            required
            name="deskripsi"
            placeholder="Deskripsi Komputasi Awan Pemerintahan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BCol, BFormGroup, VBTooltip, BRow, BFormTextarea, BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BFormTextarea,
    BInputGroup,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
      daftarJenisCloud: ['PaaS', 'IaaS', 'SaasO', 'BDaaS', 'SecaaS'],
    }
  },
}
</script>
