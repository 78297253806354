<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'"
        label="Bandwidth Internet"
        label-for="vi-bandwidth "
      >
        <validation-provider
          #default="{ errors }"
          name="Bandwidth Internet"
          rules="required"
        >
          <b-input-group append="mbps">
            <b-form-input
              id="vi-bandwidth "
              v-model="infData.bandwidth"
              type="number"
              required
              name="bandwidth"
              placeholder="Bandwidth Internet"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan lokasi dari fasilitas.'"
        label="Lokasi"
        label-for="vi-lokasi"
      >
        <validation-provider
          #default="{ errors }"
          name="Lokasi"
          rules="required"
        >
          <b-form-input
            id="vi-lokasi"
            v-model="infData.lokasi"
            required
            name="lokasi"
            placeholder="Lokasi"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan unit pemilik dari fasilitas.'"
        label="Kepemilikan"
        label-for="vi-kepemilikan"
      >
        <validation-provider
          #default="{ errors }"
          name="Kepemilikan"
          rules="required"
        >
          <v-select
            id="vi-kepemilikan"
            v-model="infData.kepemilikan"
            required
            name="kepemilikan"
            :options="dataReferensi.ref_kepemilikan"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan unit kerja/perangkat daerah yang bertanggung jawab atas fasilitas.'"
        label="Unit Kerja Penanggung Jawab"
        label-for="vi-unit_kerja"
      >
        <validation-provider
          #default="{ errors }"
          name="Unit Kerja Penanggung Jawab"
          rules="required"
        >
          <b-form-input
            id="vi-unit_kerja"
            v-model="infData.unit_kerja"
            required
            name="unit_kerja"
            placeholder="Unit Kerja Penanggung Jawab"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan klasifikasi tier fasilitas.'"
        label="Klasifikasi Tier Fasilitas"
        label-for="vi-tier"
      >
        <validation-provider
          #default="{ errors }"
          name="Klasifikasi Tier Fasilitas"
          rules="required"
        >
          <b-form-input
            id="vi-tier"
            v-model="infData.tier"
            required
            name="tier"
            placeholder="Klasifikasi Tier Fasilitas"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan tipe pengaman fasilitas.'"
        label="Sistem Pengamanan Fasilitas"
        label-for="vi-sistem_pengaman"
      >
        <validation-provider
          #default="{ errors }"
          name="Sistem Pengamanan Fasilitas"
          rules="required"
        >
          <b-form-input
            id="vi-sistem_pengaman"
            v-model="infData.sistem_pengaman"
            required
            name="sistem_pengaman"
            placeholder="Sistem Pengamanan Fasilitas"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BRow, BFormInput, BCol, BFormGroup, BInputGroup, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroup,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
    }
  },
}
</script>
