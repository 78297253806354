<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan jenis jaringan yang digunakan.'"
        label="Jenis jaringan"
        label-for="vi-jenis"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis jaringan"
          rules="required"
        >
          <b-form-input
            id="vi-jenis"
            v-model="infData.jenis"
            required
            name="jenis"
            placeholder="Jenis jaringan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan pemilik dari jaringan.'"
        label="Kepemilikan"
        label-for="vi-kepemilikan"
      >
        <validation-provider
          #default="{ errors }"
          name="Kepemilikan"
          rules="required"
        >
          <v-select
            id="vi-kepemilikan"
            v-model="infData.kepemilikan"
            required
            name="kepemilikan"
            :options="dataReferensi.ref_kepemilikan"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan nama dari pemilik jaringan.'"
        label="Nama Pemilik"
        label-for="vi-nama_pemilik "
      >
        <validation-provider
          #default="{ errors }"
          name="Nama Pemilik"
          rules="required"
        >
          <b-form-input
            id="vi-nama_pemilik"
            v-model="infData.nama_pemilik"
            name="nama_pemilik"
            placeholder="Nama Pemilik"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan unit kerja/perangkat daerah pengelola jaringan.'"
        label="Unit kerja pengelola jaringan"
        label-for="vi-unit_kerja"
      >
        <validation-provider
          #default="{ errors }"
          name="Unit kerja pengelola jaringan"
          rules="required"
        >
          <b-form-input
            id="vi-unit_kerja"
            v-model="infData.unit_kerja"
            required
            name="unit_kerja"
            placeholder="Unit kerja pengelola jaringan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan jumlah bandwidth internet yang tersedia selama 1 tahun.'"
        label="Bandwidth Internet"
        label-for="vi-bandwidth "
      >
        <validation-provider
          #default="{ errors }"
          name="Bandwidth Internet"
          rules="required"
        >
          <b-input-group append="mbps">
            <b-form-input
              id="vi-bandwidth "
              v-model="infData.bandwidth"
              required
              name="bandwidth"
              placeholder="Bandwidth Internet"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan tipe dari media jaringan.'"
        label="Tipe Media Jaringan"
        label-for="vi-tipe_media_jaringan"
      >
        <validation-provider
          #default="{ errors }"
          name="Tipe Media Jaringan"
          rules="required"
        >
          <b-form-input
            id="vi-tipe_media_jaringan"
            v-model="infData.tipe_media_jaringan"
            name="tipe_media_jaringan"
            placeholder="Tipe Media Jaringan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan tipe dari media jaringan.'"
        label="Media Lainnya"
        label-for="vi-media_lainnya"
      >
        <validation-provider
          #default="{ errors }"
          name="Media Lainnya"
          rules="required"
        >
          <b-form-input
            id="vi-media_lainnya"
            v-model="infData.media_lainnya"
            required
            name="media_lainnya"
            placeholder="Media Lainnya"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        v-b-tooltip.hover.top="'Merupakan penjelasan dari jaringan.'"
        label="Deskripsi Jaringan"
        label-for="vi-deskripsi"
      >
        <validation-provider
          #default="{ errors }"
          name="Deskripsi Jaringan"
          rules="required"
        >
          <b-form-textarea
            id="vi-deskripsi"
            v-model="infData.deskripsi"
            required
            name="deskripsi"
            placeholder="Deskripsi Jaringan"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BRow, BFormInput, BCol, BFormGroup, BFormTextarea, BInputGroup, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
    }
  },
}
</script>
