<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jenis dari Perangkat Lunak yang digunakan.'"
        label="Jenis Perangkat Lunak"
        label-for="vi-jenis"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis Perangkat Lunak"
          rules="required"
        >
          <v-select
            id="vi-jenis"
            v-model="infData.jenis"
            required
            name="jenis"
            :options="daftarJenisSoft"
            placeholder="Pilih Salah Satu"
            label="nama"
            @input="updateJenis"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="`Jenis ${nama_sistem} yang digunakan.`"
        :label="`Jenis ${nama_sistem}`"
        label-for="vi-perangkat_lunak"
      >
        <validation-provider
          #default="{ errors }"
          :name="`Jenis ${nama_sistem}`"
          rules="required"
        >
          <b-form-input
            id="vi-perangkat_lunak"
            v-model="infData.perangkat_lunak"
            required
            name="perangkat_lunak"
            :placeholder="`Jenis ${nama_sistem}`"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Jenis Lisensi Perangkat Lunak yang digunakan.'"
        label="Jenis Lisensi"
        label-for="vi-license_type"
      >
        <validation-provider
          #default="{ errors }"
          name="Jenis Lisensi"
          rules="required"
        >
          <v-select
            id="vi-license_type"
            v-model="infData.license_type"
            required
            name="license_type"
            :options="dataReferensi.ref_jenis_lisensi"
            placeholder="Pilih Salah Satu"
            label="nama"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Nama pemilik dari lisensi perangkat lunak.'"
        label="Nama Pemilik Lisensi"
        label-for="vi-pemilik_lisensi"
      >
        <validation-provider
          #default="{ errors }"
          name="Nama Pemilik Lisensi"
          rules="required"
        >
          <b-form-input
            id="vi-pemilik_lisensi"
            v-model="infData.pemilik_lisensi"
            required
            name="pemilik_lisensi"
            placeholder="Nama Pemilik Lisensi"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        v-b-tooltip.hover.top="'Penjelasan validitas dari lisensi perangkat lunak yang digunakan.'"
        label="Validitas Lisensi Perangkat Lunak"
        label-for="vi-validasi_lisensi"
      >
        <validation-provider
          #default="{ errors }"
          name="Validitas Lisensi Perangkat Lunak"
          rules="required"
        >
          <b-form-input
            id="vi-validasi_lisensi"
            v-model="infData.validasi_lisensi"
            required
            name="validasi_lisensi"
            placeholder="Validitas Lisensi Perangkat Lunak"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <b-form-group
        v-b-tooltip.hover.top="'Penjelasan dari Perangkat Lunak yang digunakan.'"
        label="Deskripsi Perangkat Lunak"
        label-for="vi-deskripsi"
      >
        <validation-provider
          #default="{ errors }"
          name="Deskripsi Perangkat Lunak"
          rules="required"
        >
          <b-form-textarea
            id="vi-deskripsi"
            v-model="infData.deskripsi"
            required
            name="deskripsi"
            placeholder="Deskripsi Perangkat Lunak"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormInput, BCol, BFormGroup, VBTooltip, BRow, BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BFormTextarea,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataReferensi: {
      type: Object,
      default: () => {},
    },
    infData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      nama_sistem: (this.infData.jenis) ? this.infData.jenis : 'Sistem Operasi',
      daftarKepemilikan: ['Milik Sendiri', 'Milik Instansi Lain', 'Milik BUMN', 'Milik Pihak Ketiga'],
      daftarJenisSoft: ['Sistem Operasi', 'Sistem Utilitas', 'Sistem Database'],
      daftarJenisLisensi: ['Lisensi Seumur Hidup', 'Lisensi Periodik', 'Kode Sumber Terbuka'],
    }
  },
  methods: {
    updateJenis() {
      this.nama_sistem = this.infData.jenis
    },
  },
}
</script>
