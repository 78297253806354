var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Jenis dari security device yang digunakan (Firewall, Intrusion Detection System, Intrusion Prevention System, Proxy, Load Balancer, Wireless intrusion prevention and detection system, Unified Threat Management, atau Network Access Control)'),expression:"'Jenis dari security device yang digunakan (Firewall, Intrusion Detection System, Intrusion Prevention System, Proxy, Load Balancer, Wireless intrusion prevention and detection system, Unified Threat Management, atau Network Access Control)'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Jenis Perangkat Keamanan","label-for":"vi-jenis"}},[_c('validation-provider',{attrs:{"name":"Jenis Perangkat Keamanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-jenis","required":"","name":"jenis","options":_vm.daftarJenisSecurity,"placeholder":"Jenis Perangkat Keamanan"},model:{value:(_vm.infData.jenis),callback:function ($$v) {_vm.$set(_vm.infData, "jenis", $$v)},expression:"infData.jenis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Status kepemilikan dari Security Device yang digunakan (Milik Sendiri; Milik Instansi Pemerintah Lain; Milik BUMN; atau Milik Pihak Ketiga).'),expression:"'Status kepemilikan dari Security Device yang digunakan (Milik Sendiri; Milik Instansi Pemerintah Lain; Milik BUMN; atau Milik Pihak Ketiga).'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Kepemilikan","label-for":"vi-ownership_status"}},[_c('validation-provider',{attrs:{"name":"Kepemilikan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vi-kepemilikan","required":"","name":"kepemilikan","options":_vm.dataReferensi.ref_kepemilikan,"placeholder":"Pilih Salah Satu","label":"nama"},model:{value:(_vm.infData.kepemilikan),callback:function ($$v) {_vm.$set(_vm.infData, "kepemilikan", $$v)},expression:"infData.kepemilikan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Nama pemilik Security Device yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'),expression:"'Nama pemilik Security Device yang digunakan, diisi jika pilihan pada status kepemilikan selain milik sendiri.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Nama Pemilik","label-for":"vi-nama_pemilik"}},[_c('validation-provider',{attrs:{"name":"Nama Pemilik","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-nama_pemilik","required":"","name":"nama_pemilik","placeholder":"Nama Pemilik"},model:{value:(_vm.infData.nama_pemilik),callback:function ($$v) {_vm.$set(_vm.infData, "nama_pemilik", $$v)},expression:"infData.nama_pemilik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Unit pengelola Security Device yang digunakan.'),expression:"'Unit pengelola Security Device yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Unit Pengelola Perangkat Keamanan","label-for":"vi-unit_operasional"}},[_c('validation-provider',{attrs:{"name":"Unit Pengelola Perangkat Keamanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vi-unit_operasional","required":"","name":"unit_operasional","placeholder":"Unit Pengelola Perangkat Keamanan"},model:{value:(_vm.infData.unit_operasional),callback:function ($$v) {_vm.$set(_vm.infData, "unit_operasional", $$v)},expression:"infData.unit_operasional"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Penjelasan dari Security Device yang digunakan.'),expression:"'Penjelasan dari Security Device yang digunakan.'",modifiers:{"hover":true,"top":true}}],attrs:{"label":"Deskripsi Perangkat Jaringan","label-for":"vi-deskripsi"}},[_c('validation-provider',{attrs:{"name":"Deskripsi Perangkat Jaringan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"vi-deskripsi","required":"","name":"deskripsi","placeholder":"Deskripsi Perangkat Jaringan"},model:{value:(_vm.infData.deskripsi),callback:function ($$v) {_vm.$set(_vm.infData, "deskripsi", $$v)},expression:"infData.deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }